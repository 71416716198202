




































































































































































































































































import { mdiCached, mdiPencil, mdiText } from '@mdi/js';
import dayjs from 'dayjs';
import Vue from 'vue';
import { maxLength, required, alphaNum, helpers } from 'vuelidate/lib/validators';
import CheckboxGroup from '@/components/generic/CheckboxGroup.vue';
import DataTable from '@/components/generic/DataTable.vue';
import PageTitle from '@/components/specific/PageTitle.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { UICommonMapper } from '@/store/modules/ui/common';
import type { DataTableHeader } from 'vuetify';

const LoggerService = ServiceFactory.get('logger');

export default Vue.extend({
  name: 'SamplePage',

  components: {
    CheckboxGroup,
    DataTable,
    PageTitle,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data(): {
    animals: {
      label: string;
      value: string;
    }[];
    colors: {
      description: string;
      name: string;
    }[];
    desserts: {
      calories: number;
      carbs: number;
      fat: number;
      iron: string;
      name: string;
      protein: number;
    }[];
    fruits: {
      label: string;
      value: string;
    }[];
    headers: DataTableHeader[];
    icons: {
      [key: string]: string;
    };
    loader: 'loading' | 'loading2' | 'loading3' | 'loading4' | null;
    loading: boolean;
    loading2: boolean;
    loading3: boolean;
    loading4: boolean;
    now?: dayjs.Dayjs;
    nowString: string;
    password: string;
    selected: {
      animal: string | undefined;
      fruit: string | undefined;
      seasons: string[];
    };
    seasons: {
      label: string;
      value: string;
    }[];
    selectedStates: string[];
    states: string[];
    text: string;
  } {
    return {
      animals: [
        {
          label: '犬',
          value: 'dog',
        },
        {
          label: '猫',
          value: 'cat',
        },
        {
          label: '兎',
          value: 'rabbit',
        },
      ],
      colors: [
        {
          description: '管理画面メインカラー、編集/更新/検索ボタン他',
          name: 'primary',
        },
        {
          description: 'その他ボタン',
          name: 'secondary',
        },
        {
          description: 'CallReachプロダクトカラー、ヘッダー',
          name: 'accent',
        },
        {
          description: '新規登録ボタン',
          name: 'info',
        },
        {
          description: '成功メッセージ',
          name: 'success',
        },
        {
          description: '警告メッセージ、注意が必要な操作のボタン',
          name: 'warning',
        },
        {
          description: 'エラーメッセージ、特に注意が必要な操作のボタン',
          name: 'error',
        },
        {
          description: 'リンク',
          name: 'anchor',
        },
      ],
      desserts: [
        {
          calories: 159,
          carbs: 24,
          fat: 6.0,
          iron: '1%',
          name: 'Frozen Yogurt',
          protein: 4.0,
        },
        {
          calories: 237,
          carbs: 37,
          fat: 9.0,
          iron: '1%',
          name: 'Ice cream sandwich',
          protein: 4.3,
        },
        {
          calories: 262,
          carbs: 23,
          fat: 16.0,
          iron: '7%',
          name: 'Eclair',
          protein: 6.0,
        },
        {
          calories: 305,
          carbs: 67,
          fat: 3.7,
          iron: '8%',
          name: 'Cupcake',
          protein: 4.3,
        },
      ],
      fruits: [
        {
          label: '林檎',
          value: 'apple',
        },
        {
          label: '桃',
          value: 'peach',
        },
        {
          label: '苺',
          value: 'strawberry',
        },
      ],
      headers: [
        {
          align: 'start',
          text: 'Dessert (100g serving)',
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
      ],
      icons: {
        mdiCached,
        mdiPencil,
        mdiText,
      },
      loader: null,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      now: undefined,
      nowString: '',
      password: '',
      seasons: [
        {
          label: '春',
          value: 'spring',
        },
        {
          label: '夏',
          value: 'summer',
        },
        {
          label: '秋',
          value: 'autumn',
        },
        {
          label: '冬',
          value: 'winter',
        },
      ],
      selected: {
        animal: undefined,
        fruit: undefined,
        seasons: [],
      },
      selectedStates: [],
      states: [
        'Alabama',
        'Alaska',
        'American Samoa',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'District of Columbia',
        'Federated States of Micronesia',
        'Florida',
        'Georgia',
        'Guam',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Marshall Islands',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Northern Mariana Islands',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Palau',
        'Pennsylvania',
        'Puerto Rico',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virgin Island',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
      ],
      text: '',
    };
  },

  computed: {
    ...UICommonMapper.mapState(['message']),
  },

  watch: {
    loader() {
      const self = this;

      const { loader } = self;

      if (loader !== null) {
        self[loader] = true;

        setTimeout(() => {
          self[loader] = false;
          self.setMessage({ color: 'success', text: 'Done!' });
          self.$$log.debug(self.message);
        }, 3000);
      }

      self.loader = null;
    },
  },

  mounted() {
    this.now = this.$$dayjs();
    this.nowString = this.now.format(this.$$dayjsFormats.displayFormatDateTimeDefault);
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage', 'setErrorMessage']),
    check() {
      this.$v.$touch();
    },
    async postLog() {
      try {
        await LoggerService.registerLog(true, {
          level: 'debug',
          message: { text: 'ログ登録テスト' },
        });

        this.setMessage({ color: 'success', text: 'ログ登録完了' });
      } catch (error) {
        this.$$log.error(error);
        this.setErrorMessage({ text: error.message });
      }
    },
    reset() {
      this.selected.animal = undefined;
      this.selected.fruit = undefined;
      this.password = '';

      this.$v.$reset();
    },
  },

  validations() {
    return {
      password: {
        // エラーメッセージを表示したい順序で定義する
        /* eslint-disable vue/sort-keys */
        required: helpers.withParams(
          // エラーメッセージの先頭に項目名を追加する例
          { name: 'パスワード', type: 'required' },
          required
        ),
        passwordComplexity: this.$$validators.passwordComplexity,
        maxLength: maxLength(255),
        /* eslint-enable vue/sort-keys */
      },
      selected: {
        animal: {
          // エラーメッセージの内容を変更する例 (定義済みのメッセージ)
          required: helpers.withParams({ type: '_requiredSelect' }, required),
        },
        fruit: {
          required: helpers.withParams(
            // エラーメッセージの先頭に項目名を追加し助詞を変更する例
            { name: '好きな果物', particle: 'を', type: '_requiredSelect' },
            required
          ),
        },
        seasons: {
          required,
        },
      },
      text: {
        // エラーメッセージを表示したい順序で定義する
        /* eslint-disable vue/sort-keys */
        required,
        alphaNum,
        maxLength: maxLength(10),
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
